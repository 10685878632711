<template>
    <div class="zj-ali-login">
        <ul class="ui-nav " :class="showToggle === 'loggedin' ? 'fn-hide': ''">
            <li class="" :class="[showToggle === 'account' ? 'active': '', !showQrcode ? 'single': '']" @click="showToggle='account'">账号登录</li>
            <li class="" :class="showToggle === 'qrcode' ? 'active': ''" v-if="showQrcode" @click="showToggle='qrcode'">扫码登录</li>
            <br class="clear-float">
        </ul>
        <div class="login login-modern" :class="showToggle === 'account' ? '': 'fn-hide'">
            <form class="ui-form" >
                <fieldset>
                    <!-- 用户名输入框 -->
                    <div class="sl-error" :class="slError ? 'sl-error-display' : ''" errortype="">
                        <span class="sl-error-text">{{slError}}</span>
                    </div>
                    <div class="ui-form-item ui-form-item-focus">
                        <label class="ui-label" >
                            <span class="ui-icon ui-icon-userDEF">
                                <img src="./icon-user.svg" alt="">
                            </span>
                            <div class="ui-poptip" :class="usernameTip ? '' : 'fn-hide'" >
                                <div class="ui-poptip-container">
                                    <div class="ui-poptip-arrow"><em></em><span></span></div>
                                    <div class="ui-poptip-content">{{usernameTip}}</div>
                                </div>
                            </div>
                        </label>
                        <input type="text" class="ui-input ui-input-normal" v-model="username" name="logonId" tabindex="1" autocomplete="off" maxlength="100" :placeholder="usernameHolder" @input="onUsername" @focus="onFocusUsername" @keypress.enter="onSubmit" >
                        <div class="ui-form-explain"></div>
                    </div>
                    
                    <!-- 输入输入框 -->
                    <div class="ui-form-item ui-form-item-20pd" >
                        <label class="ui-label" >
                            <span class="ui-icon ui-icon-securityON">
                                <img src="./icon-password.svg" alt="">
                            </span>
                            <div class="ui-poptip" :class="passwordTip ? '' : 'fn-hide'" >
                                <div class="ui-poptip-container">
                                    <div class="ui-poptip-arrow"><em></em><span></span></div>
                                    <div class="ui-poptip-content">{{passwordTip}}</div>
                                </div>
                            </div>
                        </label>
                        <input type="password" class="ui-input i-text ui-input-normal" tabindex="2" v-model="password" name="password_rsainput" oncontextmenu="return false" onpaste="return false" :placeholder="passwordHolder" oncopy="return false" oncut="return false" autocomplete="off" @input="onPassword"  @focus="onFocusPassword" @keypress.enter="onSubmit" >  
                        <div class="clear-float"></div>
                        <p class="ui-form-other" v-if="showForget">
                            <a class="textlink forgot" href="#" tabindex="5" target="_blank">忘记密码？</a>
                        </p>
                        <div class="ui-form-explain"></div>
                    </div>
    
    
                    <div id="J-checkcode" class="ui-form-item" :class="showCheckcode ? '' : 'fn-hide'">
                        <label id="J-label-checkcode" class="ui-label" seed="authcenter-switch-checkcode">
                            <i class="ui-icon iconauth ui-icon-checkcodeT" id="J-switchCheckcode"></i>
                            <div id="J-label-checkcode-pop" class="ui-poptip fn-hide" data-widget-cid="widget-1">
                                <div class="ui-poptip-container">
                                    <div class="ui-poptip-arrow">
                                        <em></em>
                                        <span></span>
                                    </div>
                                    <div data-role="content" class="ui-poptip-content">点此选择语音校验码</div>
                                </div>
                            </div>
                        </label>
                        <input type="text" placeholder="验证码" value="" class="ui-input ui-input-checkcode" id="J-input-checkcode"
                            data-type="IMAGE" maxlength="4" name="checkCode" autocomplete="off" tabindex="3"
                            data="" seed="authcenter-input-checkcode" data-widget-cid="widget-7" data-explain="">
                        <span class="sl-checkcode" id="J-checkcodeIcon"></span>
                        <div class="ui-checkcode">
                            <img class="ui-checkcode-img" id="J-checkcode-img" src="https://authsa127.alipay.com:443/login/imgcode.htm?sessionID=4827a9e3e4a0f2fe2cbbd53e8ff46651&amp;t=0.27310837246499775"
                                title="点击图片刷新验证码" alt="点击图片刷新验证码" seed="authcenter-img-checkcode">
                            <div class="ui-checkcode-voice fn-hide" id="J-checkcode-voice" data-src="https://authsa127.alipay.com:443/login/audiocode.htm?sessionID=4827a9e3e4a0f2fe2cbbd53e8ff46651&amp;r=0.2664799900061581"
                                seed="authcenter-voice-checkcode">
                                <span class="sl-process" id="J-checkcode-voice-bg"><i class="iconauth"></i></span>
                                <span class="sl-refresh" id="J-checkcode-voice-txt"><i class="ui-icon ui-icon-voicing" title="播放中"></i>播放中...</span>
                            </div>
                        </div>
                        <div class="ui-form-explain"></div>
                    </div>
    
                    <div class="ui-form-item ui-form-item-30pd" >
                        <input type="button" value="登录" class="ui-button" @click="onSubmit">
                        <!-- <span class="ui-button" @click="onSubmit">登录</span> -->
                        <p class="ui-form-other" v-if="showRegister || showMore" >
                            <a href="#" class="textlink" v-if="showMore" title="" >更多登录方式</a>
                            <a class="register" v-if="showRegister" href="" target="_blank" title="" >注册</a>
                        </p>
                    </div><!-- //submit -->
                </fieldset>
            </form>
        </div><!-- //login -->
        <div class="login login-sso login-modern" :class="showToggle === 'loggedin' ? '': 'fn-hide'" data-state="finished">
            <div class="ui-form">
                <h3 class="ui-form-title">欢迎回来</h3>
                <div class="ui-form-item">
                    <p class="ui-form-text">检测到您已经登录的账户：</p>
                    <p class="ui-form-text">
                        <span class="icon-sso" id="J-ssoLogin-icon"></span>
                        <span class="login-sso-username" id="J-ssoLogin-name"></span>
                    </p>
                </div>
    
                <div class="ui-form-item ui-form-item-30pd">
                    <!-- <input type="button" value="进入主页"  class="ui-button" @click="$emit('enter')"> -->
                    <span class="ui-button" @click="$emit('enter')">进入主页</span>
                    <p class="ui-form-other">
                        <span class="textlink" @click="showToggle = 'account'" >使用其他账号登录</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="qrcode qrcode-modern " :class="showToggle === 'qrcode' ? '': 'fn-hide'">
            <span class="qrcode-tips" id="J-qrcode-tips"></span>
            <div class="sl-error" id="J-qrcode-errorBox" errortype="">
                <span class="sl-error-text"></span>
            </div>
            <div class="qrcode-detail" id="J-qrcode-body">
                <div class="qrcode-detail-img" id="J-qrcode-img">
                    <!-- FD:security-service-template:securitycore/entrance/barcode.vm:START -->
    
                    <!-- FD:security-service-template:securitycore/common/barcode_main.vm:START -->
    
    
                    <div id="J-barcode-container" class="barcode-container">
                        <div class="mask"></div>
                        <div class="error status">
                            <p class="second">二维码出错</p>
                            <button type="button" seed="error-btn" smartracker="on">重扫</button>
                        </div>
                        <div class="timeout status">
                            <p class="second">二维码失效</p>
                            <button type="button" seed="timeout-btn" smartracker="on">刷新</button>
                        </div>
                        <div class="scanned status">
                            <p class="first">
                                <img class="barcode-icon-pic" src="https://t.alipayobjects.com/images/rmsweb/T1HEXhXfdcXXXXXXXX.png"
                                    style="width:40px;height:40px;" seed="first-barcodeIconPic" smartracker="on">
                            </p>
                            <p>扫码成功</p>
                        </div>
                        <div class="confirmed status">
                            <p class="first">
                                <img class="barcode-icon-pic" src="https://t.alipayobjects.com/images/rmsweb/T1HEXhXfdcXXXXXXXX.png"
                                    style="width:40px;height:40px;" seed="first-barcodeIconPicT1" smartracker="on">
                            </p>
                            <p>已确认</p>
                        </div>
                        <canvas width="120" height="120" class="barcode" style="width: 120px; height: 120px;"></canvas>
                    </div>
    
                    <!-- FD:security-service-template:securitycore/common/script_layout.vm:START -->
                    <!-- FD:security-service-template:securitycore/common/script_snapshot.vm:START -->
                    <!-- FD:security-service-template:securitycore/common/script_snapshot.vm:END -->
    
    
    
                    <!-- FD:security-service-template:securitycore/common/script_layout.vm:END -->
                    <!-- FD:security-service-template:securitycore/common/barcode_main.vm:END -->
                    <!-- FD:security-service-template:securitycore/entrance/barcode.vm:END -->
                </div>
                <div class="qrcode-detail-intro" id="J-qrcode-intro"></div>
            </div>
            <div class="qrcode-footer">
                <p class="qrcode-footer-tips">请使用手机扫码</p>
                <p class="qrcode-footer-help">
                    <a href="https://cshall.alipay.com/lab/help_detail.htm?help_id=23427066" title="使用帮助" seed="authcenter-qrcode-gethelp"
                        id="J-qrcode-gethelp" target="_blank">使用帮助</a>
                    <em>|</em>
                    <a href="https://mobile.alipay.com/index.htm" title="" seed="authcenter-qrcode-register"
                        target="_blank">下载手机APP</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'zj-login',
    props:{
        usernameHolder: {
            type: String,
            default: "邮箱/手机号/用户名",
        },
        passwordHolder: {
            type: String,
            default: "登录密码",
        },
        slError: {
            type: String,
            default: "",
        },
        showQrcode: {
            type: Boolean,
            default: true,
        },
        showForget: {
            type: Boolean,
            default: true,
        },
        showRegister: {
            type: Boolean,
            default: true,
        },
        showMore: {
            type: Boolean,
            default: true,
        },
        isLoggedin: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            showToggle: "account", //错误提示：account账号登录/qrcode扫描登录/loggedin已登录/留有冗余待定
            usernameTip: "",
            passwordTip: "",
            showCheckcode: false, //用户密码输入次数过多时，机器人验证


            username: "",
            password: "",
        }
    },
    created: function(){
        if(this.isLoggedin)
            this.showToggle = 'loggedin'
    },
    methods:{
        onUsername(){
            if(this.username.length === 0)
                this.usernameTip = "用户名不能为空";
            else
                this.usernameTip = "";
        },
        onPassword(){
            if(this.password.length === 0)
                this.passwordTip = "密码不能为空";
            else
                this.passwordTip = "";
        },
        onFocusUsername(){
            this.usernameTip = "";
        },
        onFocusPassword(){
            this.passwordTip = "";
        },
        onSubmit(){
            const param = {
                username: this.username,
                password: this.password,
            }
            this.$emit('submit', param)
        },
    },
    watch:{
        isLoggedin: function(isLoggedin){
            if(isLoggedin)
                this.showToggle = 'loggedin';
            else
                this.showToggle = 'account';
        },
    },
}
</script>

<style scoped>
body .fn-hide { display: none;}

.clear-float { clear: both;}

.zj-ali-login { float: right; margin: 30px 0 0 0; width: 286px; height: auto; position: fixed; right: 170px; top: 50%;margin-top: -222px; /* margin-top: -173px */}

.zj-ali-login .ui-nav { background: rgba(0,0,0,.5); _background: 0 0; filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr='#66000000', EndColorStr='#66000000');}
.zj-ali-login .ui-nav li { float: left; width: 50%; color: #fff; text-align: center; border-bottom: 2px #fff solid; font-size: 16px; padding: 15px 0 4px; }
.zj-ali-login .ui-nav li.active { color: #0AE; border-bottom-color: #0AE;}
.zj-ali-login .ui-nav li.single { width: 100%;}


.login { width: 220px; padding: 0 31px 20px 33px; position: relative; border: 1px solid #a7a7a7;}
.login .ui-label { border-top: 1px solid #a7a7a7; border-bottom: 1px solid #a7a7a7; border-left: 1px solid #a7a7a7;}
.login .textlink, .login .textlink:hover { color: #0AE; zoom: 1;}
.login-modern { border: 1px solid transparent; background: rgba(0,0,0,.5); _background: 0 0; _border: 0; filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr='#66000000', EndColorStr='#66000000');}
.login-modern .ui-input { border: 1px solid #fff;}
.login-modern form { padding-top: 20px;}
.login-modern .textlink { color: #fff; zoom: 1; transition: all 0.5s 0s; }
.login-modern .textlink:hover { color: #0AE; zoom: 1;}
.login a { color: #0AE;}


.ui-form, .ui-form * { list-style: none;}
.ui-form { font-size: 12px; line-height: 1.5; position: relative; zoom: 1;}
.ui-form fieldset { border: 0; zoom: 1; margin: 0; padding: 0;}
.ui-form, .ui-form * { list-style: none;}
.ui-form .ui-button { color: #fff; /* width: 179px; */}
.ui-form-item { padding: 0 0 15px; zoom: 1; position: relative;}
.ui-form-item:after { clear: both; content: '.'; display: block; height: 0; visibility: hidden;}
.ui-form-other { position: relative; line-height: 18px; padding-top: 7px; height: 18px; zoom: 1; _width: 100%; _overflow: hidden;}
.ui-form-other .forgot { position: absolute; right: 0; top: 5px;}
.ui-form-other .register { position: absolute; right: 0; top: 7px; font-size: 12px; color: #0AE;}
.ui-form-title { font: 18px/28px tahoma,arial,"Hiragino Sans GB","Microsoft Yahei",\5b8b\4f53; font-weight: 500; font-weight: bold\0; *font-weight: 700; padding: 26px 0 16px; width: 100%; color: #4d4d4d; text-align: center;}
.login-sso .ui-form-title { text-align: center;}
.login-modern .ui-form-title { color: #fff;}
.login-modern .ui-form-text { color: #fff; zoom: 1;}
.ui-form-text { position: relative; line-height: 18px; height: 18px; font-size: 14px; padding-bottom: 10px; color: #4d4d4d; zoom: 1;}
.ui-form-explain { display: block; height: auto; overflow: hidden;}



.ui-poptip { color: #DB7C22; z-index: 101; font-size: 12px; line-height: 1.5; zoom: 1; cursor: default;}
.ui-poptip { position: absolute; left: 0px;top: -38px;}
.ui-poptip-container { position: relative; background-color: #FFFCEF; border: 1px solid #ffbb76; padding: 5px 10px; zoom: 1; _display: inline;}
.ui-poptip-arrow { position: absolute; z-index: 10; *zoom: 1;  left: 14px; bottom: 0;}
.ui-poptip-arrow em, .ui-poptip-arrow span { position: absolute; *zoom: 1; width: 0; height: 0; border-color: rgba(255,255,255,0); border-color: transparent\0; *border-color: transparent; _border-color: tomato; _filter: chroma(color=tomato); border-style: solid; overflow: hidden; top: 0; left: 0;}
.ui-poptip-arrow em { border-width: 6px 6px 0; border-top-color: #fadcd3; top: 1px; left: 0;}
.ui-poptip-arrow span { border-width: 6px 6px 0; border-top-color: #FDEEE9;}




.ui-label { width: 39px; height: 38px; display: block; float: left; cursor: pointer;}
.ui-label .ui-icon { width: 39px; height: 38px; display: block; overflow: hidden; background-color: #aaabab; background-repeat: no-repeat; text-align: center; line-height: 38px; text-align: center;}
.ui-label .ui-icon:hover { background-color: #888; }
.ui-label .ui-icon img {margin-top: 7px ; width: 24px; height: 24px;}


.ui-input { float: left; margin: 0; padding: 0; outline: none; -webkit-appearance: none; position: relative; padding: 9px; line-height: 20px; height: 20px; color: #595959; font-size: 12px; vertical-align: middle; *vertical-align: -5px; -webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0; box-shadow: none; }
.ui-input.ui-input-normal{  width: 159px; _width: 157px;}
.ui-input-checkcode { width: 69px;}
.ui-button { display: block; text-align: center; text-decoration: none; vertical-align: middle; cursor: pointer; width: 219px; padding: 0; font-size: 18px; line-height: 36px; height: 40px; color: #fff; *zoom: 1; *overflow: visible; font-weight: 400; font-weight: bold\0; *font-weight: 700; _font-weight: bold; border: 0; outline: 0; background: #0070AD; -webkit-appearance: none; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; transition: all 0.5s 0s;}
.ui-button:hover { background: #40C0FF;}
.ui-checkcode { width: 80px; height: 40px; float: left; margin-left: 10px; position: relative; background: #fff; cursor: pointer; overflow: hidden; zoom: 1;}


.sl-error { position: relative; margin: 0; width: 212px; height: 0; opacity: .2; color: #4D4D4D; overflow: hidden; -webkit-transition-property: height,opacity; -webkit-transition-duration: .3s; -webkit-transition-timing-function: ease; -moz-transition-property: height,opacity; -moz-transition-duration: .3s; -moz-transition-timing-function: ease; transition-property: height,opacity; transition-duration: .3s; transition-timing-function: ease;}
.sl-error-display { margin: 0 auto 16px; padding: 3px 5px 3px 0; height: auto; opacity: 1; line-height: 26px; background: #FDEEE9; border: 1px solid #fadcd3;}
.sl-error-display .sl-error-text { display: block; padding-left: 10px; line-height: 20px;}


.qrcode { width: 284px; height: auto; position: relative; margin: 0 auto; border: 1px solid #a7a7a7; z-index: 10; padding-bottom: 20px;}
.qrcode-modern { border: 1px solid transparent; background: rgba(0,0,0,.5); _background: 0 0; _border: 0; filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr='#66000000', EndColorStr='#66000000');}
:root .qrcode-modern { filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, StartColorStr='#00000000', EndColorStr='#00000000')\9;}
.qrcode-footer { text-align: center; margin-top: -22px;}
.qrcode-footer-tips { font-size: 14px; line-height: 24px; color: #FFF; text-align: center; margin-bottom: 12px;}
.qrcode-footer-help { text-align: center; font-size: 12px; line-height: 20px;}
.qrcode-modern .qrcode-footer a, .qrcode-modern .qrcode-footer a:hover { color: #fff; zoom: 1; font-size: 12px;}
.qrcode-footer a, .qrcode-footer a:hover { color: #fff; zoom: 1; font-size: 12px;}

.ui-form-item .alieditContainer { width: 179px; _width: 177px; height: 40px; display: inline-block; background: #fff; border: 0; _float: left;}
.alieditContainer { display: inline-block; *display: inline; zoom: 1; width: 184px; height: 24px; vertical-align: bottom; position: relative;}


input.sixDigitPassword { position: absolute; color: #fff; opacity: 0; width: 1px; height: 1px; font-size: 1px; left: 0; -webkit-box-sizing: content-box; box-sizing: content-box; -webkit-user-select: initial; /* 取消禁用选择页面元素 */ outline: 'none'; margin-left: '-9999px'; }
div.sixDigitPassword { cursor: text; background: #fff; outline: none; position: relative; padding: 8px 0; height: 14px; border: 1px solid #cccccc; border-radius: 2px; }
div.sixDigitPassword i { float: left; display: block; padding: 4px 0; height: 7px; border-left: 1px solid #cccccc; }
div.sixDigitPassword i.active { background-image: url("https://t.alipayobjects.com/images/rmsweb/T1nYJhXalXXXXXXXXX.gif"); background-repeat: no-repeat; background-position: center center; }
div.sixDigitPassword b { display: block; margin: 0 auto; width: 7px; height: 7px; overflow: hidden; visibility: hidden; background-image: url("https://t.alipayobjects.com/tfscom/T1sl0fXcBnXXXXXXXX.png"); }
div.sixDigitPassword span { position: absolute; display: block; left: 0px; top: -1px; height: 30px; border: 1px solid rgba(82, 168, 236, .8); border: 1px solid #00ffff\9; border-radius: 2px; visibility: hidden; -webkit-box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.75), 0 0 8px rgba(82, 168, 236, 0.6); box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6); }



.qrcode-detail { position: relative; margin: 0 auto; padding: 15px 0 0; text-align: center; width: 190px; height: 170px; zoom: 1;}
.qrcode-detail .qrcode-detail-img { position: relative; width: 120px; height: 120px; padding: 7px; background: #fff; border: 1px solid #6c6c6c; margin: 5px auto; -webkit-transition: all .6s cubic-bezier(0.25,.5,.5,.9); -moz-transition: all .6s cubic-bezier(0.25,.5,.5,.9); transition: all .6s cubic-bezier(0.25,.5,.5,.9); z-index: 100; zoom: 1;}
.qrcode-footer em { padding: 0 10px; color: #4d4d4d; opacity: .72; filter: alpha(opacity=72); zoom: 1;}
.qrcode-modern .qrcode-footer em { padding: 0 10px; color: #fff; opacity: .72; filter: alpha(opacity=72); zoom: 1;}



.barcode-container {display: inline-block;_display: inline-block;display: table-cell;float: left;width:120px;height: 120px;}
.barcode-container .status, .barcode-container .mask{display: none; position: absolute;top:0;left:0;z-index:999;color:#fff;}
.barcode-container .status {text-align: center;font-size:14px;font-weight:500;font-family: tahoma,arial,"Hiragino Sans GB","Microsoft Yahei",\5b8b\4f53;color:#fff;}
.barcode-container .status .first {margin-top: 32%;}
.barcode-container .status .second {margin-top: 15%;}
.barcode-container .status button {outline: none;line-height: 1.5;height: 30px;color:#fff;border: none;background-color: #0ae;display: inline-block;margin-top: 6px;margin-top: 4px\0;text-align: center;text-decoration: none;vertical-align: middle;font-size: 14px;border-radius:2px;padding: 0 20px;cursor:pointer;}
.barcode-container .status button:hover {background-color: #00a3d2;}
.barcode-container .status button:active {position:relative;top:1px;box-shadow: 1px 1px 3px #999 inset;}
.barcode-container .mask{opacity: 0.6;filter:alpha(opacity=60);-moz-opacity:0.6;background: #000;}
.barcode-container .status, .barcode-container .mask{width:100%;height:100%;}
.barcode-container .mask {top: -7px;left: -7px;width: 112%;height: 112%;}
.barcode-container.error .status.error, .barcode-container.error .mask {display: block;}
.barcode-container.timeout .status.timeout, .barcode-container.timeout .mask {display: block;}
.barcode-container.scanned .status.scanned, .barcode-container.scanned .mask {display: block;}
.barcode-container.confirmed .status.confirmed, .barcode-container.confirmed .mask {display: block;}

.barcode-container {position: relative;}
.barcode-container .barcode img {top: 46px\0 !important; left: 46px\0 !important;}



@media only screen and (min-width: 319px) and (max-width: 768px){
    .authcenter-body-login { float: none; margin: 27px auto;}
}

</style>
