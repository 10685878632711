<template>
    <div class="login-page">
        <div class="authcenter-body">
            <h1 class="authcenter-body-logo">
                <a href="/" class="link" :title="appName" target="_top" >
                    <img class="logo" v-if="logo" :src="logo" >
                    <span>{{appName}}</span>
                </a>
            </h1>
            <!-- <zj-ali-login username-holder="用户名" :show-qrcode="false" :sl-error="slError" :showRegister="false" :showMore="false" :showForget="false" :is-loggedin="token.length > 0" @submit="onSubmit" @enter="onEnter" /> -->
            <zj-ali-login></zj-ali-login>
        </div>


        <div class="authcenter-background">
            <img class="authcenter-bg authcenter-bg-show" :src="backgroundImage">
        </div>
    </div>
</template>

<script>
import ZjAliLogin from "@/components/zj-ali-login/zj-ali-login.vue"
export default {
    name: 'login',
    components: {
        ZjAliLogin,
    },
    props:{
    },
    data() {
        return {
            logo: require("./emergency.svg"),
            appName: "应急指挥调试数据可视化系统",
            backgroundImage: require("@/static/images/loginBack.jpg"),

            slError: "",
        }
    },
    computed:{
        token(){
            if(this.$store.getters.getToken)
                return this.$store.getters.getToken;
            else
                return '';
        },
    },
    created: function(){
        // console.log('created:', this.token);
    },
    methods:{
        onSubmit(param){
            // console.log("onSubmit:", param);
            if(!param.username || param.username.length === 0){
                this.slError = "请输入用户名";
                return;
            }
            if(!param.password || param.password.length === 0){
                this.slError = "请输入登录密码";
                return;
            }

            this.slError = "";
            
            //内部演示账号
            if(param.username === "admin" && param.password === "admin123"){
                this.slError = "登录成功!";
                setTimeout(() => {
                    this.$router.push({path: "/"});
                    this.$store.dispatch('setToken', "muztakmuztakmuztak");
                }, 40)
                return;
            }

            this.$ajax({
                url: '/api/login',
                method: 'POST',
                param: {account: param.username, password: param.password}
            })
            .then( res =>{
                console.log("onSubmit: success:", res);
                const token = res.data;
                this.slError = "登录成功!";
                setTimeout(() => {
                    this.$router.push({path: "/"});
                    this.$store.dispatch('setToken', token);
                }, 40)
            })
            .catch( err => {
                console.log("onSubmit: err:", err);
                this.slError = err.msg || '服务器错误'
            });


        },
        onEnter(param){
            console.log("onEnter")
            this.$router.push({path: "/"});
        },
    },
}
</script>

<style>

fieldset, img { border: 0;}
a:link { color: #08c;}


.login-page { display: flex; flex-direction: column; }

.copyright, .copyright a, .copyright a:hover { color: #808080; }

.authcenter-body {display: flex; /* flex-direction: column; */ align-items: center; position: relative; width: 990px; height: auto; min-height: 450px; margin: 0 auto; background-position: 0 70px; background-repeat: no-repeat; }
.authcenter-body-logo {/* flex: 1; */ width: auto; height: auto;}
.authcenter-body-logo a {display: flex; width: auto; height: auto; color: #fff; font-size: 28px; line-height: 40px; cursor: pointer; text-decoration: none; position: relative;}
.authcenter-body-logo a img {margin: 10px; width: 20px; height: 20px;}
.authcenter-body-logo a span {font-size: 22px; line-height: 40px;}
/* .authcenter-body-logo .logo { width: auto; height: 40px;} */


.authcenter-foot { position: relative; width: 100%; height: 80px; color: #fff; }
.authcenter-foot-container { width: 100%; margin: 0 auto; }
.authcenter-foot-link { text-align: center; line-height: 24px; padding: 6px 0; }
.authcenter-foot-copyright, .authcenter-foot .copyright { text-align: center; line-height: 24px; color: #fff; }
.authcenter-foot a { color: #fff; text-decoration: none; }
.authcenter-background { position: absolute; width: 100%; height: 100%; top: 0; left: 0; overflow: hidden; z-index: -1; }
.authcenter-bg { position: absolute; width: 100%; height: 100%; top: 0; left: 0; overflow: hidden; opacity: 0; filter: alpha(opacity=0); -webkit-transition: opacity .8s ease; -moz-transition: opacity .8s ease; transition: opacity .8s ease; object-fit: cover; }
.authcenter-bg-show { opacity: 1; filter: alpha(opacity=100); }





@media only screen and (min-width: 319px) and (max-width: 768px){
    .authcenter-head .container, .authcenter-body, .authcenter-foot-container {width: 90%;}
    /* .authcenter-body-logo { float: none; text-align: center; width: 110px;} */
}

</style>
